'use client'
import { setHospitalIdToCookie } from '@atnd/utils'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useMemo } from 'react'

import { headerHeight } from '../../constants/size'
import { useStaff } from '../../stores/atoms/useStaff'
import { pagesPath } from '../../utils/$path'

import { formatDate } from '@atnd/dayjs'
import { clinicAliasAtom } from '@stores/atoms/clinicAliasAtom'
import { useAtomValue } from 'jotai'
import type { FC, PropsWithChildren } from 'react'
import { AuthGuard } from 'ui/layouts'

export const HospitalAuthGuard: FC<PropsWithChildren> = ({ children }) => {
	const clinicAlias = useAtomValue(clinicAliasAtom)
	const { handleLogin, staff } = useStaff()

	const router = useRouter()
	const pathname = usePathname()

	const root = pagesPath.$url().path

	const loginPath = pagesPath.login.$url().pathname

	const unauthorizedRoutes = useMemo(() => [loginPath], [loginPath])

	const redirectWhenAuthorizedPaths = useMemo(() => [root, loginPath], [loginPath, root])

	const hospital = useMemo(() => {
		return (
			staff?.hospitals.find((hospital) => hospital.url_alias === clinicAlias || hospital.alias === clinicAlias) ??
			staff?.hospitals[0]
		)
	}, [clinicAlias, staff?.hospitals])

	useEffect(() => {
		if (!staff) {
			handleLogin()
		}
	}, [staff, handleLogin])

	useEffect(() => {
		if (pathname && staff && hospital && redirectWhenAuthorizedPaths.includes(pathname)) {
			const appTop = pagesPath._clinicAlias(hospital.url_alias).reservations._date(formatDate(new Date())).$url().path

			setHospitalIdToCookie(hospital.url_alias, hospital.id)
			router.push(appTop)
		}
	}, [hospital, pathname, redirectWhenAuthorizedPaths, router, staff])

	return (
		<AuthGuard
			isAuthorized={!!staff}
			unauthorizedRoutes={unauthorizedRoutes}
			redirectWhenAuthorizedPaths={redirectWhenAuthorizedPaths}
			headerHeight={headerHeight}
			currentPath={pathname ?? ''}
			withFirebase={false}
		>
			{children}
		</AuthGuard>
	)
}
