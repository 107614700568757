'use client';
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { app } from '@atnd/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { MAIN_PADDING } from 'shared/constants';
import { Spinner } from '@atnd/ui/loading';
import { Center } from '../components/mui/Box';
export const AuthGuard = ({ children, unauthorizedRoutes, redirectWhenAuthorizedPaths = [], headerHeight, isAuthorized, currentPath, login, logout, withFirebase = true, }) => {
    const [isLoading, setIsLoading] = useState(withFirebase);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!withFirebase)
            return;
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const idToken = await user.getIdToken();
                await login?.(idToken);
                setIsLoading(false);
            }
            else {
                await logout?.();
                setIsLoading(false);
            }
        });
        return () => unsubscribe();
    }, [withFirebase]);
    const requireAuth = !unauthorizedRoutes.includes(currentPath);
    const shouldRedirectToLogin = requireAuth && !isAuthorized;
    const shouldRedirectWhenAuthorized = isAuthorized && redirectWhenAuthorizedPaths.includes(currentPath);
    return (_jsx(HandleLoading, { isLoading: isLoading, shouldRedirectWhenAuthorized: shouldRedirectWhenAuthorized, headerHeight: headerHeight, shouldRedirectToLogin: shouldRedirectToLogin, children: children }));
};
const HandleLoading = ({ children, headerHeight, isLoading, shouldRedirectToLogin, shouldRedirectWhenAuthorized, }) => {
    if (isLoading || shouldRedirectToLogin || shouldRedirectWhenAuthorized) {
        return (_jsx(Center, { height: `calc(100dvh - ${headerHeight + MAIN_PADDING * 2}px)`, children: _jsx(Spinner, {}) }));
    }
    return _jsx(_Fragment, { children: children });
};
