import { punctuation } from 'shared/constants'

import { useStaff } from '@stores/atoms/useStaff'

import { useClinicAlias } from './useClinicAlias'

export const useHospital = () => {
	const { staff } = useStaff()

	const paramsClinicAlias = useClinicAlias()

	const currentHospital = staff?.hospitals?.find((hospital) => hospital.url_alias === paramsClinicAlias)

	const selectedHospitalValue = `${currentHospital?.id}${punctuation}${paramsClinicAlias}`

	return {
		hospitals: staff?.hospitals,
		currentHospital,
		paramsClinicAlias,
		selectedHospitalValue,
	}
}
