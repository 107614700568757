import { useAtomValue, useSetAtom } from 'jotai'
import { useParams } from 'next/navigation'

import { hospitalAccessToken } from '@atnd/utils'
import { loginAtom, logoutAtom, staffAtom } from './staffAtom'

export const useStaff = () => {
	const params = useParams()
	const staff = useAtomValue(staffAtom)

	const login = useSetAtom(loginAtom)
	const logout = useSetAtom(logoutAtom)

	const handleLogin = async () => {
		const token = hospitalAccessToken.get()
		const clinic_alias = params.clinicAlias?.toString()

		if (token && clinic_alias) {
			login({ clinic_alias, token })
		}
	}

	return { handleLogin, staff, login, logout }
}
