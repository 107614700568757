'use client'

import { ThemeProvider } from '@emotion/react'
import { CssBaseline, createTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { jaJP } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'shared/features'
import { theme } from 'ui/theme'

import { Provider } from 'jotai'
import type { FC, PropsWithChildren } from 'react'

const hospitalTheme = createTheme({
	...theme,
	palette: {
		...theme.palette,
	},
	typography: {
		...theme.typography,
		h1: { fontSize: '1.6rem' },
		h2: { fontSize: '1.3rem' },
		h3: { fontSize: '1.2rem' },
		h4: { fontSize: '1.1rem' },
		h5: { fontSize: '1.0rem' },
		h6: { fontSize: '0.9rem' },
		subtitle1: { fontSize: 14 },
		body1: { fontSize: 14 },
		body2: { fontSize: 12 },
	},
})

export const AppProviders: FC<PropsWithChildren> = ({ children }): JSX.Element => {
	return (
		<div>
			<Provider>
				<CssBaseline />
				<ThemeProvider theme={hospitalTheme}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={jaJP}
						dateFormats={{ monthAndYear: 'YYYY年 MM月' }}
						localeText={{
							previousMonth: '前月を表示', // < のツールチップ
							nextMonth: '次月を表示', // > のツールチップ
						}}
					>
						<SnackbarProvider>{children}</SnackbarProvider>
					</LocalizationProvider>
				</ThemeProvider>
			</Provider>
		</div>
	)
}
